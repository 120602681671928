import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick } from 'vue';
import useTablePagination from '@/hooks/pagination';
import Cookies from "js-cookie";
import moment from 'moment';
import { insertAdministrator, updateAdminPassWord, updateAdministrator, selectAdminByPagination, selectLikeCompanyName } from '@/api/role';
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import jurisdictionSetModal from './components/accountSetModal.vue';
import { getAdminMenu, setAdminMenu } from '@/api/role';
import { getMenuTree, treeForeach, treeFilter } from '@/utils/setMenuTree'; //表头

const columns = [{
  title: '账号类型',
  dataIndex: 'accountType',
  slots: {
    customRender: 'accountType'
  }
}, {
  title: '账号名称',
  dataIndex: 'userName'
}, {
  title: '账号属性',
  dataIndex: 'menuGenre',
  slots: {
    customRender: 'menuGenre'
  }
}, {
  title: '公司名称',
  dataIndex: 'companyName'
}, {
  title: '项目名称',
  dataIndex: 'belongProjectName'
}, {
  title: '添加时间',
  dataIndex: 'foundTime',
  sorter: (a, b) => {
    let aTimeString = a.foundTime;
    let bTimeString = b.foundTime;
    let aTime = new Date(aTimeString).getTime();
    let bTime = new Date(bTimeString).getTime();
    return aTime - bTime;
  }
}, {
  title: '到期时间',
  dataIndex: 'pastTime'
}, {
  title: '存储空间',
  dataIndex: 'spaceInfo',
  slots: {
    customRender: 'spaceInfo'
  }
}, {
  title: '状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  width: 250,
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    QuestionCircleOutlined,
    jurisdictionSetModal
  },

  setup() {
    const formRef = ref();
    const formState = reactive({
      companyName: undefined,
      menuGenre: undefined,
      state: undefined
    }); //点击查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    }; //表格


    const tableData = ref([]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    }; //获取表格数据


    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        companyName: formState.companyName ? formState.companyName : null,
        menuGenre: formState.menuGenre ? formState.menuGenre : null,
        state: formState.state === undefined ? null : formState.state
      };
      loading.value = true;

      try {
        const res = await selectAdminByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    };

    const formRef1 = ref();
    const formState1 = reactive({
      userName: undefined,
      userPassword: "",
      menuGenre: undefined,
      companyName: undefined,
      belongProjectName: "",
      pastTime: undefined,
      initSpaceNum: 50,
      usageRate: 0,
      accountType: undefined
    });
    const rules = {
      userName: [{
        required: true,
        message: '账号名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      menuGenre: [{
        required: true,
        message: '账号属性不能为空',
        trigger: 'change',
        type: "number"
      }],
      accountType: [{
        required: true,
        message: '账号类型不能为空',
        trigger: 'change',
        type: "number"
      }],
      userPassword: [{
        required: true,
        message: '密码不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      companyName: [{
        required: true,
        message: '公司名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      belongProjectName: [{
        required: true,
        message: '项目名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      pastTime: [{
        required: true,
        message: '到期时间不能为空',
        trigger: 'change',
        type: 'object'
      }]
    };
    const menuVisible = ref(false);

    const handlePrint = () => {
      menuVisible.value = true;
      modelState.value = false;
      formState1.userName = undefined;
      formState1.userPassword = "";
      formState1.menuGenre = undefined;
      formState1.companyName = undefined;
      formState1.belongProjectName = "";
      formState1.pastTime = undefined;
      formState1.initSpaceNum = 50;
      formState1.usageRate = 0;
      formState1.accountType = undefined;
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    }; //提交


    const handleOk = () => {
      formRef1.value.validate().then(async () => {
        const obj = {};
        Object.assign(obj, formState1); // console.log(obj)

        obj.pastTime = obj.pastTime.startOf('day').format('YYYY-MM-DD');
        loading.value = true;
        let res = null;

        if (modelState.value) {
          res = await updateAdministrator(obj); //修改
        } else {
          res = await insertAdministrator(obj); //添加
        }

        if (res.code === 200) {
          _message.success(res.message);

          formRef1.value.resetFields();
          loading.value = false;
          menuVisible.value = false;
          getTableData();
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
        loading.value = false;
      });
    };

    const closeVisible = () => {
      formRef1.value.resetFields();
      menuVisible.value = false;
    };

    const companyNameData = ref([]);

    const handleSearch = async val => {
      if (!val) {
        companyNameData.value = [];
        return;
      }

      const param = {
        companyName: val === " " ? null : val
      };
      let res = await selectLikeCompanyName(param); // console.log(res)

      try {
        companyNameData.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            companyNameData.value.push({
              value: ixt.companyName,
              text: ixt.companyName
            });
          }); //如果当前返回的数据中没有当前的值，则把当前值放进去

          let bool = res.data.some(ixt => ixt.companyName === val);

          if (!bool && val !== " ") {
            companyNameData.value.unshift({
              value: val,
              text: val
            });
          }
        } else {
          companyNameData.value.push({
            value: val,
            text: val
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const modelState = ref(false);

    const toModification = val => {
      // console.log('toModification',val)
      modelState.value = true;
      Object.assign(formState1, val);
      formState1.initSpaceNum = val.totalSpaceNum;
      formState1.pastTime = moment(formState1.pastTime, 'YYYY-MM-DD');
      menuVisible.value = true;
    };

    const onResetPassword = async id => {
      // console.log('onResetPassword',id)
      const res = await updateAdminPassWord({
        id
      });

      if (res.code === 200) {
        _message.success(res.message);
      } else {
        _message.error(res.message);
      }
    }; //权限配置


    const jusVisible = ref(false);
    const keyValue = ref([]);
    const jobNo = ref("");
    const menuGenre = ref(null);
    const belongProjectVal = ref(null);

    const tocConf = async val => {
      menuGenre.value = val.menuGenre;
      belongProjectVal.value = val.belongProject; // console.log(val.belongProject)

      keyValue.value = [];
      isAllUid.value = val.uid;
      let res = await getAdminMenu({
        belongProject: val.belongProject
      }); // window.resObj = res.data.menuList

      try {
        if (res.code === 200 && res.data.menuList.length) {
          const treeData = getMenuTree(res.data.menuList, 2); // console.log(treeData)

          treeForeach(treeData, node => {
            keyValue.value.push(node.key);
          }); // console.log(22222222,keyValue.value )
        } else {
          keyValue.value = [];
        }

        nextTick(() => {
          jusVisible.value = true; // jobNo.value = key
        });
      } catch (error) {
        console.log('查询岗位权限菜单', error);
      }
    };

    const updateValue = val => {
      // console.log(val)
      keyValue.value = val;
    };

    const isOk = async (val, isAll) => {
      const param = {
        // jobNo:jobNo.value,
        belongProject: belongProjectVal.value,
        menuGenre: menuGenre.value,
        menuList: val,
        isAll
      };
      window.paramObj = param;
      let res = await setAdminMenu(param);

      if (res.code == 200) {
        _message.success('操作成功！');

        jusVisible.value = false;
      } else {
        _message.error('操作失败！');
      }
    }; //关闭权限配置弹窗


    const closeJusVisible = val => {
      jusVisible.value = val;
    };

    const isAllUid = ref('');
    onMounted(() => {
      getTableData();
    });
    return {
      formRef,
      formState,
      onSubmit,
      resetForm,
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      formRef1,
      formState1,
      menuVisible,
      handlePrint,
      rules,
      disabledDate,
      handleOk,
      closeVisible,
      companyNameData,
      handleSearch,
      modelState,
      toModification,
      onResetPassword,
      jusVisible,
      keyValue,
      jobNo,
      tocConf,
      updateValue,
      isOk,
      closeJusVisible,
      menuGenre,
      belongProjectVal,
      isAllUid
    };
  }

});