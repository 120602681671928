import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.includes.js";
import { defineComponent, ref, reactive, watch, computed, toRefs, onMounted } from 'vue';
import { getMenuAll } from '@/api/role';
import { getMenuTree, treeFilter } from '@/utils/setMenuTree';
import { useStore } from 'vuex';
import Cookies from "js-cookie";
export default defineComponent({
  props: {
    jusVisible: {
      type: Boolean,
      default: false
    },
    keyValue: {
      type: Array,
      default: () => []
    },
    menuGenre: {
      type: Number
    },
    belongProjectVal: {
      type: Number
    },
    isAllUid: {// type: Number,
    }
  },
  emits: ['closeJusVisible', 'updateValue', 'isOk'],

  setup(props, context) {
    const closeJusVisible = () => {
      context.emit('closeJusVisible', false);
    };

    const state = reactive({
      treeData: [// {
        //   title: '人员管理',
        //   id: '0-0',
        //   children: [
        //     {
        //       title: '员工列表',
        //       id: '0-0-0',
        //       children: [
        //         {
        //           title: '新增',
        //           id: '0-0-0-1',
        //           type: 'btn'
        //         },
        //         {
        //           title: 'leaf',
        //           id: '0-0-0-2',
        //           type: 'btn'
        //         },
        //       ],
        //     },
        //     {
        //       title: '黑名单管理',
        //       id: '0-0-1',
        //     },
        //     {
        //       title: '白名单管理',
        //       id: '0-0-2',
        //     },
        //     {
        //       title: '访客管理',
        //       id: '0-0-3',
        //     },
        //   ],
        // },
        // {
        //   title: '考勤管理',
        //   id: '0-1',
        //   children: [
        //     {
        //       title: '当日考勤',
        //       id: '0-1-0',
        //     },
        //     {
        //       title: '考勤报表',
        //       id: '0-1-1',
        //     },
        //   ],
        // },
        // {
        //   title: '考核管理',
        //   id: '0-2',
        //   children: [
        //     {
        //       title: '考核报表',
        //       id: '0-2-0',
        //     },
        //     {
        //       title: '工资表',
        //       id: '0-2-1',
        //     },
        //   ],
        // },
      ],
      replaceFields: {
        // key: 'id',
        children: 'childMenuList',
        title: 'menuTitle'
      }
    });
    const currt = ref([]); // console.log('props.keyValue',props.keyValue)
    // 已勾选的节点

    const checkedKeys = computed({
      get: () => props.keyValue,
      set: val => context.emit('updateValue', Array.isArray(val) ? val : val.checked)
    }); // 获取所有父节点的key

    const getParentsKey = (treeNode, arr = []) => {
      // console.log('获取所有父节点的key',treeNode, arr)
      if (treeNode.eventKey) {
        arr.push(treeNode.eventKey);
        return getParentsKey(treeNode.vcTreeNode, arr);
      }

      return arr;
    }; // 获取所有子节点的key


    const getChildrenKeys = (treeNode, arr = []) => {
      // debugger
      // console.log('获取所有子节点的key',treeNode)
      if (treeNode.childMenuList && treeNode.childMenuList.length > 0) {
        // console.log(treeNode.childMenuList, 'childMenuList')
        return treeNode.childMenuList.reduce((prev, curr) => {
          if (curr.childMenuList && curr.childMenuList.length > 0) {
            prev.push(...getChildrenKeys(curr, prev));
          }

          return prev.concat([curr.key]);
        }, arr);
      }

      return arr;
    }; // 勾选事件处理函数


    const onCheck = (keys, {
      node,
      checked
    }) => {
      let tempKeys = checkedKeys.value; // 子节点选中，父节点必然要选中

      if (checked) {
        // console.log(...checkedKeys.value)
        tempKeys = [...new Set([...checkedKeys.value, ...keys.checked, ...getParentsKey(node.vcTreeNode), ...getChildrenKeys(node.dataRef)])];
      } else {
        const childrenKeys = getChildrenKeys(node.dataRef); // console.log(childrenKeys, 'childrenKeys')

        if (childrenKeys.length > 0) {
          tempKeys = keys.checked.filter(item => !childrenKeys.includes(item));
        } else {
          tempKeys = keys.checked;
        }

        if (node.dataRef.menuType !== 3) {
          var _node$vcTreeNode, _node$vcTreeNode$data, _node$vcTreeNode$data2;

          //获取所有同级节点
          const childMenuList = ((_node$vcTreeNode = node.vcTreeNode) === null || _node$vcTreeNode === void 0 ? void 0 : (_node$vcTreeNode$data = _node$vcTreeNode.dataRef) === null || _node$vcTreeNode$data === void 0 ? void 0 : (_node$vcTreeNode$data2 = _node$vcTreeNode$data.childMenuList) === null || _node$vcTreeNode$data2 === void 0 ? void 0 : _node$vcTreeNode$data2.map(item => item.key)) || []; // 如果当前所有选中的节点中没有包含任何一个直属子节点

          if (!childMenuList.some(item => tempKeys.includes(item))) {
            tempKeys = tempKeys.filter(item => {
              var _node$vcTreeNode2;

              return item != ((_node$vcTreeNode2 = node.vcTreeNode) === null || _node$vcTreeNode2 === void 0 ? void 0 : _node$vcTreeNode2.eventKey);
            });
          }
        }
      }

      checkedKeys.value = tempKeys;
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      const tree = treeFilter(state.treeData, node => props.keyValue.includes(node.key)); // console.log(tree)

      const tree1 = getMenuList2(getMenuList1(tree)); // console.log('getMenuList1(tree)',getMenuList1(tree))
      // console.log('tree1',tree1)
      // tree.

      context.emit('isOk', tree1, isAll.value == true ? 1 : 0);
    };

    const store = useStore(); //判断是否是总站
    // const isMasterStation = computed(() => Cookies.get('belongProject') == '10000')
    //获取treeData

    const getTreeData = async () => {
      let res = await getMenuAll({
        menuGenre: props.menuGenre,
        belongProject: props.belongProjectVal
      });

      try {
        // 总公司管理岗人员按钮权限只能有材料里面的打印和下载材料清单的按钮权限
        // state.treeData =getMenuTree(res.data.menuList,2,isMasterStation.value)
        state.treeData = getMenuTree(res.data.menuList, 2); // console.log(state.treeData)
      } catch (error) {
        console.log('查询菜单', error);
      }
    };

    const getMenuList1 = arr => {
      let newArr = [];
      arr.forEach(item => {
        // console.log(11111111,item)
        let obj = {
          menuNo: item.key,
          menuType: item.menuType,
          buttonList: null,
          idd: item.idd
        };

        if (item.menuType == 2 && item.childMenuList) {
          obj.buttonList = [];
          item.childMenuList.forEach(ixt => {
            obj.buttonList.push({
              buttonNo: Number(ixt.key.split('-')[0]),
              idd: ixt.idd
            });
          });
          item.childMenuList = null;
        }

        if (item.menuType == 2 && !item.childMenuList) {
          item.childMenuList = null;
        }

        if (item.menuType != 3 && item.childMenuList) {
          obj.childMenuList = getMenuList1(item.childMenuList);
        }

        newArr.push(obj);
      });
      return newArr;
    };

    const getMenuList2 = arr => {
      let newArr = [];
      arr.forEach(item => {
        let obj = {
          menuNo: item.menuNo,
          buttonList: null,
          idd: item.idd
        };

        if (item.buttonList) {
          obj.buttonList = item.buttonList; // obj.childMenuList = null
        }

        if (item.childMenuList) {
          obj.childMenuList = getMenuList2(item.childMenuList);
        } else {
          obj.childMenuList = null;
        }

        newArr.push(obj);
      });
      return newArr;
    };

    const isAll = ref(false);
    onMounted(() => {
      getTreeData();
    });
    return {
      closeJusVisible,
      loading,
      handleOk,
      ...toRefs(state),
      checkedKeys,
      onCheck,
      isAll
    };
  }

});